<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  hideIfEmpty,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { useCopy } from '@/compositions/copy';

export default {
  name: 'ConnectionCardGeneral',
  setup() {
    const entity = inject('entity');
    const { copyWithAlert } = useCopy();

    const items = computed(() => [
      ...hideIfEmpty(entity.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: entity.value.description
        })
      ]),
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          icon: '$uuid',
          title: entity.value.id
        },
        {
          click: () => copyWithAlert(entity.value.id)
        }
      ),
      genServiceNameItem(entity.value.name, {
        click: () => copyWithAlert(entity.value.name)
      })
    ]);

    return { items };
  }
};
</script>

<style></style>
